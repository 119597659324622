import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textStepperFactors'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextStepper = makeShortcode("TextStepper");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "what-factors-can-increase-the-risk-of-depression-and-clinical-anxiety-in-teenagers"
    }}>{`What factors can increase the risk of depression and clinical anxiety in teenagers?`}</h1>
    <TextStepper id="textStepperFactors" sequenceSpeed={1200} fadeSpeed={1200} list={[{
      text: 'Struggling to adapt to the birth of a sibling'
    }, {
      text: 'Pressure from doing too many activities'
    }, {
      text: 'Fear of failure'
    }, {
      text: 'Preparing for higher education'
    }, {
      text: 'Having a negative view of themselves'
    }, {
      text: 'Weight and body image concerns'
    }, {
      text: 'Death or illness of a family member or close friend'
    }, {
      text: 'Moving or changing schools'
    }, {
      text: 'Feeling inadequate'
    }, {
      text: 'Finding homework and school demands overwhelming'
    }, {
      text: 'Being bullied'
    }, {
      text: 'Peer pressure'
    }, {
      text: 'Pressure from sports coaches'
    }, {
      text: 'High self expectations'
    }, {
      text: 'Problems with friends'
    }, {
      text: 'Puberty'
    }, {
      text: 'Feeling a lack of control over their lives'
    }, {
      text: 'Not doing well at school'
    }, {
      text: 'Parental separation or divorce'
    }, {
      text: 'Breaking up with a boyfriend/girlfriend'
    }, {
      text: 'Struggling to become more independent'
    }, {
      text: 'Arguing with parents'
    }, {
      text: 'Sexual identity'
    }, {
      text: 'Increased access to alcohol & drugs'
    }, {
      text: 'What they think others think of them'
    }, {
      text: 'Financial problems'
    }, {
      text: 'Parental pressure'
    }, {
      text: 'Feeling socially isolated'
    }, {
      text: 'Difficulty working out what to do with their life'
    }]} mdxType="TextStepper" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      